import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { Router } from 'preact-router';

import { routes } from './constants';
import { runAnalytics } from './analytics';

import { Layout } from './components/layout';

import About from '../routes/about';
import ErrorView from '../routes/error';
import Homepage from '../routes/homepage';
import Legal from '../routes/legal';
import Licenses from '../routes/licenses';
import LienholderSupport from '../routes/lienholder-support';
import Pay from '../routes/pay';
import Plans from '../routes/plans';
import Reviews from '../routes/reviews';
import Join from '../routes/join';
import OldPrivacyPolicy from '../routes/old-privacy-policy';
import PrivacyForm from '../routes/privacy-form';

const App = () => {
    const handleRoute = () => {
        if (typeof window !== 'undefined') {
            // Cross-domain cookies are a bit complicated, in particular, this will not work
            // on localhost testing by default: update your etc/hosts file to point
            // "app.localhost.com", "www.localhost.com" to localhost to make it work.
            // Will work on dev/staging/prod, as it sets the root cookie for withhugo.com
            const expires = new Date();
            expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 365 * 2);
            const domain = window.location.href.includes('localhost')
                ? 'localhost.com'
                : 'withhugo.com';
            if (window.location.search) {
                const querySearchParams = new URLSearchParams(window.location.search);
                if (querySearchParams.get('ref')) {
                    document.cookie = `signupCode=${querySearchParams.get(
                        'ref',
                    )}; expires=${expires.toUTCString()}; domain=${domain}`;
                }
                if (querySearchParams.get('quoteCode')) {
                    document.cookie = `quoteCode=${querySearchParams.get(
                        'quoteCode',
                    )}; expires=${expires.toUTCString()}; domain=${domain}`;
                }
                document.cookie = `utm_tracking=${btoa(
                    window.location.search,
                )}; expires=${expires.toUTCString()}; domain=${domain}`;
            }
            if (document.referrer) {
                const ref = new URL(document.referrer);
                if (ref.hostname.indexOf('withhugo.com') === -1) {
                    document.cookie = `utm_referrer=${
                        ref.hostname
                    }; expires=${expires.toUTCString()}; domain=${domain}`;
                }
            }
            window.analytics.page();
            if (process.env.HUGO_ENV !== 'local') {
                // document.body.scrollTop = 0;
            }
        }
    };

    useEffect(() => {
        runAnalytics();
    }, []);

    return (
        <Layout>
            <Router onChange={handleRoute}>
                <Homepage path={routes.HOMEPAGE} />

                {/* secondary */}
                <Plans path={routes.PLANS} />
                <Pay path={routes.PAY} />

                {/* tetriray */}
                <About path={routes.ABOUT} />
                <Join path={routes.JOIN} />
                <Reviews path={routes.REVIEWS} />
                <Licenses path={routes.LICENSES} />
                <LienholderSupport path={routes.LIENHOLDER_SUPPORT} />
                <Legal path={routes.LEGAL} />
                <OldPrivacyPolicy path={routes.OLD_PRIVACY_POLICY} />
                <PrivacyForm path={routes.PRIVACY_FORM} />
                <ErrorView path={routes.ERROR} default />
            </Router>
        </Layout>
    );
};

export default App;
